import { useDomain } from '@netfront/gelada-identity-library';

const useNewEkardoSiteUrls = (options) => {
  const { environment, port } = options ?? {};

  const { getDomain } = useDomain();

  const getBaseUrl = () => {
    const domain = getDomain();

    // eslint-disable-next-line detect-unwanted-words/detect-unwanted-words-in-code
    if (domain === 'localhost') {
      const portSuffix = port ? `:${port}` : '';

      return `http://${domain}${portSuffix}`;
    }

    const environmentPrefix = environment ? `${environment}-` : '';

    return `https://${environmentPrefix}admin.${domain}`;
  };

  return {
    getBaseUrl,
  };
};

export { useNewEkardoSiteUrls };
